/**
 *  Page: Approach '/approach'
 */

import React from "react"
import { graphql, StaticQuery } from "gatsby"

// CSS
import "../utils/css/v2.css"
import PageContent from "../../content/pages/approach";
import { PageContentBuilder} from "../components/partials/page-content-builder"

const ApproachPage = ({ data }, location) => {
    const siteTitle = data.site.siteMetadata.title, headerActive = "Approach", keywords = ["technical distributed teams"];
    return <PageContentBuilder siteTitle={siteTitle} headerActive={headerActive} location={location} title={`Approach`} keywords={keywords} subsection={'approach'} api={true} PageContent={PageContent}/>
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ApproachPage location={props.location} data={data} {...props} />
    )}
  />
)