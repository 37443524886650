module.exports = {
    "title" : "Approach",
    "keywords" : ["distributed teams"],
    "contents" : [
        {
            "type" : "hero_div_with_subtitle",
            "className" : "hero-title-div--approach-padding", 
            "content" : {
                "hero" : {
                    "text" : "Our secret sauce for building amazingly successful distributed teams.",
                    "highlighted" : {
                        "text" : "successful",
                        "brush" : {
                            "brushClassName" : "approach-brush-hero",
                            "brushType" : "Brush5"
                        } 
                    }
                },
                "subtitle" : [
                    "While building great distributed teams may seem like magic, it's all about understanding your people, their cultures, treating them as equals, and creating a positive working environment. We work with our clients in providing best practices and masterclasses in outsourcing success.",
                    "The right culture isn't created unilaterally, but is created with a healthy mix of understanding our client's cultures while helping them understand the cultures of the Philippines."
                ]
            }
        },
        {
            "type" : "section_div",
            "section" : {
                "title" : "Formula",
                "number" : "01",
                "className" : "section-div approach-section--formula"
            },
            "contents" : [
                {
                    "type" : "hero_with_enumeration",
                    "content" : {
                        "hero" : {
                            "text" : "We follow four basic but important rules when working with our clients",
                            "highlighted" : {
                                "text" :  "clients",
                                "brush" : {
                                    "brushClassName" : "approach-medium-brush",
                                    "svgClassName" : "medium-brush-type",
                                    "spanClassName" : "medium-brush-animation-text",
                                    "brushType" : "Brush4",
                                    "viewBox" : "-20 -100 100 100"
                                }
                            }
                        },
                        "enumeration" : {
                            "className": "rule-div-list--approach-formula-list",
                            "list" : [
                                {
                                    "number" : "01",
                                    "title" : {
                                        "text" : "The employee comes first",
                                        "highlighted" : "first"
                                    },
                                    "description" : "Everything we do - we put the employee first. Whether it’s creating the best possible built environment that promotes creativity, collaboration and wellbeing, or creating a buzzing community where employees are meeting new people and attending fun and interesting events. We do this while offering best practices to our clients around culture, employee engagement to build trust and foster long-term distributed success."
                                },
                                {
                                    "number" : "02",
                                    "title" : {
                                        "text" : "Taking time to understand",
                                        "highlighted" : "understand"
                                    },
                                    "description" : "We take the time to ask who our clients are, what is the problem they’re trying to solve and why are distributed teams needed. Understanding whether we are the right fit early allows us to align with our client’s goals, help them understand where we can create value and build the best possible team that enhances their existing capabilities, accesses broader talent and ultimately unlocks greater possibilities for their business."
                                },
                                {
                                    "number" : "03",
                                    "title" : {
                                        "text" : "Quality over quantity",
                                        "highlighted" : "Quality"
                                    },
                                    "description" : "We pride ourselves in having one of the lowest attrition rates in the Philippines. We achieve this because we rely on screening heavily upfront, putting forward only the best quality people, coupled with industry insights, best practices and years of experience in developing successful distributed teams. Our goal isn’t to be the biggest player, but the BEST distributed partner that will stand the test of time as our client’s needs and technology stacks change."
                                },
                                {
                                    "number" : "04",
                                    "title" : {
                                        "text" : "Collaborate early and often",
                                        "highlighted" : "Collaborate"
                                    },
                                    "description" : "Getting input from our clients and stakeholders at every stage of the process is key to building a successful distributed team. By constantly checking in and encouraging continuous engagement from our clients, we ensure that the team will not only feel a part of their business, but collaborations will be met with joy, creativity and outcomes. Collaborating often with employees not only allows us to identify and proactively address any concerns early on, but to also learn what more we could be doing."
                                }
                            ]
                        }
                    }
                }
            ]
        },
        {
            "type" : "section_div",
            "section" : {
                "title" : "Campus",
                "number" : "02",
                "className" : "section-div approach-section--campus"
            },
            "contents" : [
                {
                    "type" : "hero_with_description",
                    "content" : {
                        "hero" : {
                            "text" : "The first vertical campus for distributed teams"
                        },
                        "description" : {
                            "text" : "With our own 20 storey, purpose-built tech campus and industry-leading facilities, we're able to attract and retain the very best people in the Philippines. As employer of choice, this means you always get access to top-quality talent, and your team are reaching their full potential, as they're able to work in a space that promotes innovation, collaboration and creativity.",
                            "plinkarrow" : {
                                "display" : true,
                                "plink" : {
                                    "to" : "campus",
                                    "className" : "p-link p-color--psg-white p-link--animated-hover-left section-description-link",
                                    "onClickFadeOut" : true,
                                    "ignoreScrollTop" : true,
                                    "pinkArrowClassName" : "section-description-link--arrow",
                                    "text" : "See our Campus"
                                }
                            }
                        }
                    }
                }
            ]
        },
        {
            "type" : "section_div",
            "section" : {
                "title" : "Methodologies",
                "number" : "03",
                "className" : "section-div approach-section--methodologies"
            },
            "contents" : [
                {
                    "type" : "section_accordion_list",
                    "content" : {
                        "list" : [
                            {
                                "title" : "Discovery",
                                "image" : "discovery",
                                "content" : "Every great distributed team starts with a clear strategy and executive support, but to get there, you have to have clarity on what kind of team you’re building, the technology stacks, who you’re building it for, and the commercial goals you’d like to achieve. This is why we always begin with the Discovery phase, which helps executives, stakeholders and our team get clear on the team’s direction, technology, and business objectives."
                            },
                            {
                                "title" : "Source",
                                "image" : "source",
                                "content" : "With a clear strategy in place, we can begin sourcing the candidates for your business - allowing you to be as active in the interview process as desired. Using industry standard tools and our talent hunters, we source, interview, and shortlist candidates based on your input. After multiple rounds of interviews and incorporating any final internal tests or examinations your business may have, we then issue job offers to the best candidates selected by you."
                            },
                            {
                                "title" : "Kick Off",
                                "image" : "kickOff",
                                "content" : "Before we start your team’s kick off, we sit down with you to discuss on-boarding and whether you would like to use your internal on-boarding program or ours. We then ask for your executive sponsorship and following the successful on-boarding, we then familiarise them with their office space and typically throw a small welcome party for your team, as the newest members of the family."
                            },
                            {
                                "title" : "Support",
                                "image" : "support",
                                "content" : "Now that your team is live, we move to our continual support phase. Having built many successful distributed teams, we like to play an active role in supporting you and your distributed team. We will also help work with you to offer best practices on engaging, supporting and keeping your team loved, recognised, and reaching new heights. And if you need another pair of hands, eyes, or brains along the way, we are more than happy to get involved and help wherever possible."
                            },
                            {
                                "title" : "Scale",
                                "image" : "scale",
                                "content" : "We work with you to build and iterate until you have a proven business case and playbook on how to create successful distributed teams specifically for your organisation. Typically we find at that point, other departments become interested in how to build successful distributed teams as well. Want it for all departments? Or just a few? No problem. With a proven business case, playbook in hand, and an understanding of your business, we are in a position to scale as slowly or quickly as you like."
                            }
                        ]
                    }
                }
            ]
        },
        {
            "type" : "contact_div",
            "section" : {
                "title" : "Contact",
                "number" : "04",
            },
            "content" : {
                "text" : "Are we the partners you've been searching for? Tell us about your requirements.",
                "highlighted" : "Tell us about your requirements."
            }
        }
    ]
}